.main_wrapper {
    padding-top: 70px;
    display: flex;
    flex-wrap: nowrap;
}

.objects_wrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-content: baseline;
    margin: 0 -2px;
    padding-left: 25px;
    flex-grow: 1;
    overflow: hidden;
}

@media screen and (max-width: 350px) {
    .objects_wrapper {
        padding-left: 15px;
    }
}