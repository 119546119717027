.header_filter {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.object_id {
    position: relative;
    width: 110px;
}

.object_id input {
    width: 100%;
    border: 1px solid #BDBDBD;
    height: 30px;
    border: 1px solid #cccccc;
    padding: 0 5px;
    font-size: 12px;
    line-height: 30px;
}

.object_sort {
    position: relative;
    border: 1px solid #C4C4C4;
}

.object_sort::before {
    content: "";
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 6px solid black;
}

.filter_sort_by {
    position: relative;
    width: 175px;
    height: 100%;
    padding: 6px 8px;
    cursor: pointer;
}

.sort_by_value {
    font-size: 12px;
    line-height: 14px;
}

.sort_arrow {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
}

.filter_sort_options {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    list-style: none;
    padding: 0;
    background-color: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 2;
}

.filter_sort_options.active {
    display: block;
}

.filter_sort_option {
    display: flex;
}

.sort_option_value {
    padding: 2px 8px;
    margin-bottom: 0;
    font-size: 12px;
    line-height: 12px;
    color: #000000;
    width: 80%;
    transition: .3s ease;
}

.filter_sort_option:hover .sort_option_value {
    background-color: #0275D8;
    color: #ffffff;
}

.filter_sort_option button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    transition: .3s ease;
}

.filter_sort_option button.down img {
    transform: rotate(180deg);
}

.filter_sort_option button:hover {
    background-color: #C4C4C4;
}

@media screen and (max-width:475px) {

    .object_id {
        width: 50px;
        margin-right: 20px;
    }

}

@media screen and (max-width:325px) {

    .object_id {
        width: 50px;
        margin-right: 0px;
    }

}
