.object_item {
    padding: 0 5px !important;
    margin-bottom: 7px;
}

.card_content {
    border: 1px solid #ECEEEF;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.card_image {
    position: relative;
    min-height: 185px;
}

.card_image img {
    display: block;
    max-width: 100%;
    height: 185px;
    margin: 0 auto;
    object-fit: unset;
}

.image_label {
    position: absolute;
    left: -1px;
    top: 15px;
    background: #55B1A9;
    border-radius: 1px;
    padding: 5px;
}

.image_label span {
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    color: white;
}

.image_info {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: -webkit-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(55, 58, 60, 0.65);
    padding: 4px 10px;
}

.image_info span {
    color: white;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    cursor: pointer;
}

.image_info span i {
    display: inline-block;
    margin-right: 7px;
}

.image_info .video {
    margin-left: 14px;
}

.complex_name span {
    font-weight: 700;
}

.card_info {
    padding: 0 10px;
    margin-top: 15px;
    height: 100%;
    display: -webkit-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.upper_info {
    display: flex;
    justify-content: space-between;
}

.upper_address {
    min-height: 28px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    text-decoration: underline;
    color: #2D9CDB;
    margin-bottom: 10px;
    padding-right: 10px;
}

.lower_address {
    min-height: 32px;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 16px;
    color: #373A3C;
}

.price {
    text-align: right;
    transform: translateY(-6px);
}

.main_price {
    font-weight: bold;
    font-size: 26px;
    line-height: 40px;
    color: #4F4F4F;
    white-space: nowrap;
}

.price_for_meter {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    color: #6E777E;
    margin-top: 6px;
}

.lower_info {
    display: flex;
    justify-content: space-between;
}

.lower_info .object_part p {
    margin-bottom: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 25px;
    color: #55595C
}

.lower_info .complex_part span {
    font-size: 10px;
    line-height: 25px;
    color: #55595C;
}

.card_button {
    align-self: flex-end;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: 115px;
}

.card_free {
    text-align: right;
}

.card_free span {
    display: inline-block;
    text-align: right;
}

.free_date {
    background-color: #55B1A9;
    color: white;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    padding: 2px;
    margin-bottom: 5px;
}

.free_price {
    color: #55B1A9;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 5px;
}

.card_button a {
    background: #55B1A9;
    border: 1px solid #55B1A9;
    border-radius: 4px;
    color: white;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    text-decoration: none;
    padding: 7px 13px;
    white-space: nowrap;
}

.card_button a:hover {
    text-decoration: none;
    color: #fff;
}

@media screen and (max-width:475px) {
    .object_item {
        min-width: 320px;
    }
}

@media screen and (max-width:350px) {
    .object_item {
        min-width: 280px;
    }

    .lower_info {
        flex-direction: column;
    }
}