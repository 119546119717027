.side_filter {
    position: -webkit-sticky;
    position: sticky;
    top: 65px;
    height: calc(85vh - 65px);
    width: 1px;
    flex-basis: 0;
    flex-shrink: 0;
    border-right: 1px solid #BFBFBF;
    transition: 0.5s ease;
    z-index: 10;
    /* flex: 0 0 280px; */
}

.side_filter.open {
    flex-basis: 280px;
}

.open_filter {
    position: absolute;
    width: 10px;
    height: 100px;
    background-color: #ECEEEF;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 0 18px 18px 0;
    border: none;
    z-index: 10;
    outline: none;
    cursor: pointer;
}

.open_filter::before {
    content: "";
    position: absolute;
    height: 85%;
    background-color: #ffffff;
    width: 1px;
    top: 50%;
    transform: translateY(-50%);
    left: 3px;
}

.open_filter::after {
    content: "";
    position: absolute;
    height: 85%;
    background-color: #ffffff;
    width: 1px;
    top: 50%;
    transform: translateY(-50%);
    left: 6px;
}