.header {
    position: fixed;
    max-width: 1365px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 5px;
    border-bottom: 1px solid #C4C4C4;
    background-color: #fff;
    z-index: 20;
}