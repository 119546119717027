/* CSS RESET */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* ///////// */

* {
	font-family: sans-serif;
	box-sizing: border-box;
}

.custom_container {
    max-width: 1385px;
    padding: 0 10px;
    margin: 0 auto;
    position: relative;
}

.addressModal .modal-header,
.addressModalSub .modal-header {
    padding: 7px 1rem;
}

.addressModal .modal-title,
.addressModalSub .modal-title {
    font-size: 14px;
	line-height: 1;
	font-weight: 700;
	color: #4F4F4F;
}

.addressModal .modal-body,
.addressModalSub .modal-body {
    padding: 10px 7px;
}

.addressModalSub .modal-body {
  min-height: 340px;
}

.addressModal .modal-footer,
.addressModalSub .modal-footer {
    padding: 10px 7px;
}

.addressModal .modal-footer button,
.addressModalSub .modal-footer button {
	width: 120px;
    display: block;
    font-size: 12px;
    font-weight: bold;
    height: auto;
    text-transform: uppercase;
    height: 30px;
    margin-right: 10px;
    color: #fff;
    border-radius: 4px;
    border: none;
}

.addressModal .modal-footer button:last-child,
.addressModalSub .modal-footer button:last-child {
    margin-right: 0;
}

.addressModal .modal-footer .submit_modal,
.addressModalSub .modal-footer .submit_modal  {
    background: #55B1A9;
}

.addressModal .modal-footer .close_modal,
.addressModalSub .modal-footer .close_modal {
    background: #e27836;
}

.addressModal .listbox,
.addressModalSub .listbox {
	display: flex;
}

.addressModal .listbox .item,
.addressModalSub .listbox .item {
	flex: 1;
    border-right: 1px solid #f0f2fa;
    padding: 0 5px;
}

.addressModal .listbox .item:last-child,
.addressModalSub .listbox .item:last-child  {
	border-right: none;
}

.addressModalSub .listbox.arr .item {
    position: relative;
    padding: 0 30px;
    flex: 1 1 100%;
}

.addressModalSub .listbox.arr .item:last-child {
    width: 447px;
    flex: 0 0 447px;
}

.addressModal h6 {
    display: flex;
    align-items: center;
    margin: 0 0 15px;
}

.addressModal h6 span {
    font-weight: bold;
    font-size: 12px;
    color: #4F4F4F;
}

.addressModal h6 label {
    display: flex;
    align-items: center;
    font-size: 10px;
    line-height: 13px;
    margin-left: 5px;
    margin-bottom: 0;
    color: #4F4F4F;
    font-weight: bold;
}

.addressModal h6 label input {
    margin-right: 5px;
}

.address_button {
    display: block;
    width: 100%;
    text-decoration: none;
    color: #000;
    padding: 0 20px 0 7px;
    display: block;
    font-size: 12px;
    line-height: 18px;
    position: relative;
    border: none;
    background: none;
    text-align: left;
    transition: all 0.35s ease 0s;
    outline: none !important;
}

.address_button:hover {
    background: #f0f2fa;
}

.address_button.active {
    background: #f0f2fa;
    font-weight: bold;
}

.addressModalSub h6 {
    margin: 0 0 15px;
    font-weight: bold;
    font-size: 12px;
    color: #4F4F4F;
}

.addressModalSub .item i {
    position: absolute;
    left: auto;
    right: -10px;
    top: -3px;
    font-size: 20px;
    background: #fff;
    padding-bottom: 10px;
}

.addressModalSub .item label {
    position: relative;
    display: block;
    font-weight: normal;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 5px 0;
}

.addressModalSub .item label input {
    width: 14px;
    height: 14px;
    border-radius: 2px;
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    flex: 0 0 14px;
}

.addressModalSub .item label span {
    display: inline-block;
    font-size: 12px;
    line-height: 1.2;
    color: #373A3C;
    vertical-align: middle;
    padding-left: 10px;
}

.addressModalSub .item .column-2 {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
} 

.addressModalSub .item .column-2 label {
    flex: 1 1 50%;
    padding-left: 5px;
    padding-right: 5px;
}

.pagination {
  display: flex;
  justify-content: center;
  width: 100%;
}

.hidden {
  display: none !important;
}

.addressModalSub .landmark_label.no-orient input {
  display: none;
}

.addressModalSub .landmark_label.no-orient b {
  position: absolute;
    left: 4px;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
    height: 14px;
    background-color: transparent;
    border: 1px solid #B12D00;
    border-radius: 2px;
}

.addressModalSub .landmark_label.no-orient b::before {
    display: none;
    content: "";
    position: absolute;
    width: 12px;
    height: 7px;
    border-bottom: 3px solid #B12D00;
    border-left: 3px solid #B12D00;
    top: 2px;
    left: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.addressModalSub .landmark_label.no-orient input:checked + b::before {
  display: block;
}

.addressModalSub .landmark_label.no-orient span {
    color: #B12D00;
    font-weight: bold;
    padding-left: 23px;
}

.disabled_content {
  pointer-events: none;
  opacity: 0.5;
}

.lds_ring {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 12px;
    width: calc(100% - 12px);
    height: calc(100vh - 62px);
    z-index: 10;
    background-color: white;
  }
  
  .lds_ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #2D9CDB;
    border-radius: 50%;
    animation: lds_ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #2D9CDB transparent transparent transparent;
  }
  .lds_ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds_ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds_ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds_ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .custom_hidden {
    display: none;
  }

  @media screen and (max-width:475px) {

    .addressModal .listbox, 
    .addressModalSub .listbox {
      flex-direction: column;
    }
    
    .addressModal .listbox .item, 
    .addressModalSub .listbox .item {
      margin-bottom: 15px;
    }

    .addressModal .listbox .item:last-child, 
    .addressModalSub .listbox .item:last-child {
      margin-bottom: 0;
    }

    .addressModalSub .item i {
      display: none;
    }

    .addressModalSub .item .column-2 label {
      flex: 1 1 100%;
    }

    .addressModalSub .listbox.arr .item:last-child {
      width: 100%;
      flex: 0 0 100%;
    }

  }
  
