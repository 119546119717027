.object_sort {
    position: relative;
    border: 1px solid #C4C4C4;
}

.object_sort::before {
    content: "";
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 6px solid black;
}

.filter_sort_by {
    position: relative;
    width: 175px;
    height: 100%;
    padding: 6px 8px;
    cursor: pointer;
}

.sort_by_value {
    font-size: 12px;
    line-height: 14px;
}

.sort_arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transform-origin: center;
    right: 20px;
}

.sort_arrow.sort_arrow_down {
    transform: translateY(-50%) rotate(180deg);
}

.filter_sort_options {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    list-style: none;
    padding: 0;
    background-color: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 2;
}

.filter_sort_options.active {
    display: block;
}

.filter_sort_option {
    display: flex;
}

.sort_option_value {
    padding: 2px 8px;
    margin-bottom: 0;
    font-size: 12px;
    line-height: 12px;
    color: #000000;
    width: 80%;
    transition: .3s ease;
}

.filter_sort_option:hover .sort_option_value {
    background-color: #0275D8;
    color: #ffffff;
}

.filter_sort_option button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    transition: .3s ease;
}

.filter_sort_option button::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) rotate(-45deg);
    width: 6px;
    height: 6px;
    border-top: 1px solid #828282;
    border-right: 1px solid #828282;
    transform-origin: center;
}

.filter_sort_option button.down::before  {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-100%) rotate(135deg);
    width: 6px;
    height: 6px;
    border-top: 1px solid #828282;
    border-right: 1px solid #828282;
    transform-origin: center;
}

.filter_sort_option button:hover {
    background-color: #C4C4C4;
}