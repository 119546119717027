.filter_row {
    display: block;
    margin-bottom: 8px;
}

.description {
    display: block;
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    margin: 0 0 3px;
    color: #545b62;
}

.main_location_input {
    width: 100%;
    background-color: #e9ecef;
    color: #373a3c;
    font-size: 13px;
    border: 1px solid #E4E6E9;
    padding: 0 5px;
    height: 30px;
    line-height: 30px;
}

.sub_location {
    cursor: pointer;
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    color: #545b62;
}

.sub_location:hover {
    color: #2D9CDB;
}

.preview {
    padding-bottom: 10px;
}

.preview > div {
    font-size: 12px;
    line-height: 1.5;
    color: #545b62;
}