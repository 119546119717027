.single_content {
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
}

.single_address {
    margin-bottom: 8px;
}

.upper_address p {
    font-size: 16px;
    line-height: 20px;
    color: #2D9CDB;
    margin-bottom: 5px;
}

.upper_address p span {
    display: inline-block;
    padding: 4px;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    color: white;
    background: #2D9CDB;
    border-radius: 1px;
    margin-right: 5px;
}

.lower_address p {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #4F4F4F;
    margin-bottom: 5px;
}

.single_slider {
    margin-bottom: 5px;
}

.main_image {
    padding-right: 2px;
}

.main_image img {
    display: block;
    max-width: 100%;
    height: 230px;
    margin: 0 auto;
    object-fit: unset;
}

.sub_images {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2px;
    padding: 0 0 10px 0;
    border-bottom: 1px solid #BDBDBD;
}

.sub_image {
    padding-left: 0;
    padding-right: 2px;
    margin-bottom: 3px;
}

.sub_image img {
    width: 65px;
    height: 65px;
    object-fit: cover;
    cursor: pointer;
    border: 1px solid transparent;
    transition: 0.3s ease;
}

.sub_image.active img {
    border: 1px solid #EB5757;
    transition: 0.3s ease;
}

.price_contact {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding-bottom: 50px;
}

.single_price {
    padding-left: 15px;
}

.total_price,
.price_for_meter {
    text-align: right;
    margin-bottom: 0;
}

.total_price, 
.total_price span {
    font-weight: bold;
    font-size: 28px;
    line-height: 25px;
    color: #4F4F4F;
    white-space: nowrap;
}

.total_price span {
    display: inline-block;
    margin-right: 5px;
}

.price_for_meter, 
.price_for_meter span {
    font-size: 14px;
    line-height: 25px;
    color: #6E777E;
}

.auth {
    font-size: 14px;
    line-height: 14px;
    color: #2D9CDB;
    margin-bottom: 0;
    text-align: center;
}

.number {
    font-weight: bold;
    font-size: 16px;
    line-height: 25px;
    color: #373A3C;
    margin-bottom: 0;
    text-align: center;
}

.single_call {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    display: inline-block;
    width: 340px;
    color: white;
    background: #55B1A9;
    border: 1px solid #55B1A9;
    border-radius: 4px;
    padding: 12px 0;
    text-decoration: none;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 21px;
}

.single_call:hover {
    text-decoration: none;
    color: #fff;
}

.info_block {
    margin-top: 7px;
}

.info_title {
    font-weight: bold;
    font-size: 14px;
    line-height: 25px;
    color: #4F4F4F;
}

.info_body {
    border-bottom: 1px solid #DADADA;
    padding-bottom: 20px;
}

.info_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
}

.item_title {
    font-size: 12px;
    line-height: 25px;
    color: #828282;
}

.item_value {
    font-size: 12px;
    line-height: 25px;
    color: #4F4F4F;
}

.item_value.no_info {
    color: #DADADA;
}

.single_description {
    margin-top: 7px;
}

.descr_header {
    font-weight: bold;
    font-size: 10px;
    line-height: 30px;
    color: #4F4F4F;
}

.descr_body {
    font-size: 10px;
    line-height: 14px;
}

.descr_body span {
    text-transform: uppercase;
    display: block;
}

.map_wrapper {
    height: 226px;
}

.single_buttons {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.single_button {
    text-decoration: none;
    display: inline-block;
    width: 165px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 21px;
    padding: 12px 0;
    border-radius: 4px;
    color: white;
    text-align: center;
}

.single_button:hover {
    color: #fff;
    text-decoration: none;
}

.single_button.back {
    background: #2D9CDB;
    border: 1px solid #0275D8;
    margin-right: 13px;
}

.single_button.call {
    background: #55B1A9;
    border: 1px solid #55B1A9;
    text-transform: uppercase;
}

.load {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

.world_side {
    display: inline-block;
}

.world_side:last-child .coma {
    display: none;
}

.multi {
    align-items: unset;
}

.multi .item_value {
    text-align: right;
    max-width: 50%;
    align-self: flex-end;
}

@media screen and (min-width:768px) {
    .single_description {
        transform: translateY(100px);
    }

    .map_wrapper {
        height: 310px;
    }
}

@media screen and (min-width:992px) {
    .single_description {
        transform: translateY(0px);
    }

    .map_wrapper {
        height: 390px;
    }
}


