.form_wrapper {
    padding-right: 10px;
    position: relative;
    z-index: 10;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: none;
}

.form_wrapper.opened {
    display: block;
}

.filter_row {
    display: block;
    margin-bottom: 8px;
}

.description {
    display: block;
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    margin: 0 0 3px;
    color: #545b62;
}

.main_location_input {
    width: 100%;
    background-color: #e9ecef;
    color: #373a3c;
    font-size: 13px;
    border: 1px solid #E4E6E9;
    padding: 0 5px;
    height: 30px;
    line-height: 30px;
}

.sub_location {
    cursor: pointer;
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    color: #545b62;
}

.sub_location:hover {
    color: #2D9CDB;
}

.filter_row_flex {
    display: flex;
    align-items: flex-end;
}

.filter_row_flex > div {
    padding: 0;
}

.flexbox {
    width: 100%;
    display: flex;
    margin: 0 -2px;
}

.item {
    width: 50%;
    padding: 0 2px;
}

.item input {
    width: 100%;
    font-size: 13px;
    color: #373a3c;
    border: 1px solid #E4E6E9;
    height: 30px;
    padding: 0 5px;
}

.floor_label {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 6px;
}

.floor_label:last-child {
    margin-bottom: 0;
}

.floor_label input {
    margin-right: 2px;
}

.floor_label span {
    font-weight: bold;
    font-size: 10px;
    line-height: 10px;
    white-space: nowrap;
    color: #545b62;
}

.filter_row_head {
    display: flex;
}

.filter_row_head > div {
    padding: 0;
}

.filter_row_head label {
    display: flex;
    align-items: center;
}

.filter_row_head label input {
    margin-right: 2px;
}

.filter_row_head label span {
    font-weight: bold;
    font-size: 10px;
    line-height: 10px;
    color: #414141;
}

.filter_row_body {
    display: flex;
    margin: 0 -2px;
}

.filter_row_body > div {
    padding: 0 2px;
}

.filter_row_body input {
    width: 100%;
    font-size: 13px;
    color: #373a3c;
    border: 1px solid #E4E6E9;
    height: 30px;
    padding: 0 5px;
}

.filter_row_body select {
    width: 100%;
    font-size: 13px;
    color: #373a3c;
    border: 1px solid #E4E6E9;
    height: 30px;
    padding: 0 5px;
    background-color: #fff;
}

.room_count_label {
    flex: 0 0 20%;
    width: 20%;
    height: 30px;
    cursor: pointer;
}

.room_count_label input {
    display: none;
}

.room_count_label span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #000;
    border: 1px solid #E4E6E9;
    font-size: 12px;
    line-height: 1.2;
    text-align: center;
    transition: .3s ease;
}


.room_count_label input:checked + span {
    background: #ECEEEF;
    color: #373A3C;
    border-color: #CCCCCC;
    font-weight: bold;
}

.room_input {
    flex: 0 0 20%;
    width: 20%;
}

.room_input input  {
    width: 100%;
    height: 30px;
    font-size: 13px;
    color: #373a3c;
    border: 1px solid #E4E6E9;
    height: 30px;
    padding: 0 5px;
}

.filter_buttons_row {
    display: flex;
    justify-content: center;
}

.filter_buttons_row button {
    width: 120px;
    display: block;
    font-size: 12px;
    font-weight: bold;
    height: auto;
    text-transform: uppercase;
    height: 30px;
    margin-right: 10px;
    color: #fff;
    border-radius: 4px;
    border: none;
}

.filter_buttons_row button:last-child {
    margin-right: 0;
}

.submit_filter {
    background: #55B1A9;
}

.reset_filter {
    background: #e27836;
}

.date_input::-webkit-inner-spin-button,
.date_input::-webkit-clear-button {
    display: none;
    -webkit-appearance: none;
}

