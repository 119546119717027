.filter_obj {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-right: 20px;
}

.obj_item {
    position: relative;
    width: 24.5%;
    height: 35px;
    cursor: pointer;
}

.obj_item input {
    display: none;
}

.obj_item span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #000;
    border: 1px solid #ccc;
    font-size: 10px;
    line-height: 1.2;
    text-align: center;
    transition: .3s ease;
}

.obj_item input:checked + span {
    background-color: #97999c;
    color: #fff;
}

@media screen and (max-width:475px) {
    .obj_item span {
       font-size: 9px;
    }
}
