.lds_ring {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    /*top: 0;*/
    left: 12px;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: white;
}

.lds_ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #2D9CDB;
    border-radius: 50%;
    animation: lds_ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #2D9CDB transparent transparent transparent;
}
.lds_ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds_ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds_ring div:nth-child(3) {
    animation-delay: -0.15s;
}
.footer_links {
    background-color: #ffffff;
    padding: 40px 20px;
}

.footer_title {
    margin-bottom: 40px;
    font-size: 22px;
    color: #000;
}

.footer_address {
    display: flex;
    font-size: 16px;
    color: #000;
    margin-bottom: 20px;
}

.footer_address p {
    margin-right: 10px;
    font-weight: 700;
}
.footer_address p svg {
    margin-right: 5px;
}

.footer_phone {
    display: flex;
    align-content: center;
    color: #000;
    font-size: 16px;
    margin-bottom: 20px;
}
.footer_phone_title {
    font-weight: 700;
    margin-right: 10px;
}
.footer_phone_title svg{
    margin-right: 5px;
}
.footer_phone_number {
    display: flex;
    flex-direction: column;
    color: #000;
}
.footer_phone_number a {
    color: #000;
}
.footer_email {
    display: flex;
    align-content: center;
    color: #000;
    font-size: 16px;
    margin-bottom: 20px;
}

.footer_email p {
    margin-right: 10px;
    font-weight: 700;
}
.footer_email p svg {
    fill: #000;
    margin-right: 5px;
}
.footer_email a {
    color: #000;
}
.footer_social svg {
    width: 26px;
    margin-right: 15px;
    fill: #000;
}
.footer_useful_links ul {
    list-style: none;
}
.footer_useful_links ul li {
    margin-bottom: 10px;
    padding-bottom: 10px;
}
.footer_useful_links ul li:before {
    content: "•";
    color: #000;
    display: inline-block;
    width: 16px;
    margin-left: -10px
}
.footer_useful_links ul li:nth-child(1) {
    border-bottom: 1px solid #000;
    border-style: dashed;
}
.footer_useful_links ul li:nth-child(2) {
    border-bottom: 1px solid #000;
    border-style: dashed;
}
.footer_useful_links ul li a{
    color: #000;
}
.footer_contacts {

}
.footer_label {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    color: #000;
}
.footer_label input {
    outline: none;
    border: 1px solid #000;
    height: 30px;
    margin-top: 5px;
}

.footer_form textarea {
    width: 100%;
    height: 70px;
    margin-bottom: 5px;
}
.footer_form button {
    outline: none;
    border: none;
    display: block;
    margin: 0 0 0 auto;
    color: #fff;
    background-color: #4F4F4F;
    padding: 10px;
    min-width: 150px;
}
